<!--
 * @Author: wyq
 * @Date: 2021-06-18 10:39:24
 * @LastEditTime: 2021-07-19 14:31:28
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\addGroupQuestion.vue
-->
<template>
	<div class="page-add-new-question">
		<content-container title="新增同源题">
			<div slot="title" class="tip-title">
				打
				<span>*</span>
				为必填项
			</div>
			<el-form ref="form" label-width="110px" label-position="left" size="mini" class="form">
				<el-form-item label="题型">
					<el-select v-model="questionType">
						<el-option
							v-for="item in $store.state.typeList"
							:key="'gradeList' + item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
					<el-button type="primary" @click="synchronize">同步母题</el-button>
				</el-form-item>
			</el-form>
			<component
				v-if="questionType"
				:is="componentName"
				:questionForm="questionForm"
				@save="submit"
				@preview="preview"
				:submitLoading="submitLoading"
			>
				<addQuestionVideo ref="addQuestionVideo"></addQuestionVideo>
			</component>
			<preview-dialog
				:form="previewForm"
				:type="questionType"
				:visiable.sync="previewVisiable"
			></preview-dialog>
		</content-container>
	</div>
</template>
<script>
import singleChoiceQuestion from '../components/edit/singleChoiceQuestion.vue'
import fillsUpQuestion from '../components/edit/fillsUpQuestion.vue'
import freeResponseQuestion from '../components/edit/freeResponseQuestion'
import multipleChoiceQuestion from '../components/edit/multipleChoiceQuestion.vue'
import addQuestionVideo from '../components/edit/addQuestionVideo.vue'
export default {
	components: {
		singleChoiceQuestion,
		fillsUpQuestion,
		freeResponseQuestion,
		multipleChoiceQuestion,
		addQuestionVideo,
	},
	data() {
		return {
			questionType: null,
			questionForm: {},
			previewVisiable: false,
			motherQuestionForm: null,
			previewForm: {},
			submitLoading: false,
		}
	},
	computed: {
		componentName() {
			const map = {
				1: 'singleChoiceQuestion',
				2: 'fillsUpQuestion',
				3: 'freeResponseQuestion',
				4: 'multipleChoiceQuestion',
			}
			return map[this.questionType]
		},
		grade() {
			return Number(this.$route.query.grade)
		},
		subject() {
			return Number(this.$route.query.subject)
		},
		id() {
			return Number(this.$route.query.id)
		},
	},
	methods: {
		submit(val) {
			console.log('submit', val)
			this.submitLoading = true
			let params = {
				motherQuestionId: this.id,
				groupQuestionType: this.questionType,
				grade: this.grade,
				subject: this.subject,
				...val,
			}
			this.$service
				.addGroupQuestion(params)
				.then((res) => {
					this.submitLoading = false
					this.$refs.addQuestionVideo.submitVideo(res.questionId)
					this.$message({
						message: '保存成功！',
						type: 'success',
					})
					this.$router.push({
						name: 'Detail',
						query: {
							id: res.questionId,
							grade: this.grade,
							subject: this.subject,
						},
					})
				})
				.catch(() => {
					this.submitLoading = false
				})
		},
		preview(val) {
			this.previewForm = val
			this.previewVisiable = true
		},
		// 同步母题
		synchronize() {
			if (this.questionType) {
				if (this.motherQuestionForm) {
					// 格式化数据
					this.questionForm = this.$formattedData(this.motherQuestionForm)
				} else {
					this.motherQuestionForm = this.getQuestionSingleInfo()
				}
			} else {
				this.$message({
					message: '请先选择题型',
					type: 'warning',
				})
			}
		},
		// 获取母题数据
		getQuestionSingleInfo() {
			let params = {
				grade: this.grade,
				subject: this.subject,
				questionId: this.id,
			}
			this.$service.getQuestionSingleInfo(params).then((res) => {
				// 格式化数据
				this.questionForm = this.$formattedData(res)
				return res
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.page-add-new-question {
	padding: 24px;
	.content-container + .content-container {
		margin-top: 24px;
	}
	.form {
		margin-top: 24px;
	}
	::v-deep .tip-title {
		display: inline-block;
		margin-left: 20px;
		color: #565656;
		font-weight: 400;
		span {
			color: #ff3838;
		}
	}
}
</style>
